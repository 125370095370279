/*
 * @Author: 蛋蛋
 * @Date: 2021-03-03 12:49:50
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-03-03 16:26:37
 */
import React,{Component} from 'react';
import styles from './index.module.css';
class Loading extends Component {
  render() {
    return (
      <div className={styles.loadEffect}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    )
  }
}
export default Loading