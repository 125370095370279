/*
 * @Author: 麦勇潮
 * @Date: 2021-01-25 17:25:46
 * @LastEditors: 麦勇潮
 * @LastEditTime: 2021-01-25 17:50:43
 */
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);