/*
 * @Author: 蛋蛋
 * @Date: 2021-03-31 17:27:41
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-03-31 17:40:52
 */

import domain from '@/config/domain.js'
import {getSession,sdkAjax} from '@/common/utils'

/**
 * @description 语言列表
 */
export const initLang = ()=>{
  let userInfo = getSession('CLIENT_USER_INFOR') // 客户端传过来的参数，包括：user_info、ext_footer、fuse_channel_info
  let input = {
    ct: 'init',
    ac: 'lang',
    ...userInfo.user_info,
    ...userInfo.fuse_channel_info,
    ...userInfo.ext_footer,
    game_id: userInfo.fuse_channel_info.pf_game_id,
    sdk_type: 'h5'

  }
  return sdkAjax('sdk: 获取语言列表', input, {
    domain: domain.__gameapiDomain.get()
  })
}