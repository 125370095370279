/* eslint-disable no-restricted-globals */
/*
 * @Author: 麦勇潮
 * @Date: 2020-12-30 16:27:07
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-03-04 15:43:16
 */
import {version} from '@/model/config.js'
let ENV = version
let hostname = window.location.host.substring(window.location.host.indexOf('.'))   
if(hostname === '.qianxi5.com') ENV = 'dev'
if(hostname === '.ksgame.com') ENV = 'ks' // 凯撒域名匹配
if(hostname === 'localhost:3000') {
  ENV = 'dev'
  hostname = '.xw888.net'
}
if (/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(location.hostname)) ENV = 'mj'
const __gamepayDomain = {
  dev: `pf-pay${hostname}`, 
  dq: `pf-pay${hostname}`,
  mj: `pf-pay${hostname}`,
  ks: `new-pay${hostname}`,
  get () {
    return `//${__gamepayDomain[ENV]}/`
  }
}
const __gameapiDomain = {
  dev: `pf-api${hostname}`,
  dq:  `pf-api${hostname}`,
  mj:  `pf-api${hostname}`,
  ks:  `pf-api${hostname}`,
  get () {
    console.log(ENV)
    return `//${__gameapiDomain[ENV]}/`
  }
}
const __fuseDomain = {
  dev: `rh-api${hostname}`,
  dq:  `rh-api${hostname}`,
  mj:  `rh-api${hostname}`,
  ks:  `jh-api${hostname}`,
  get () {
    console.log(ENV)
    return `//${__fuseDomain[ENV]}/`
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  __gamepayDomain,
  __gameapiDomain,
  __fuseDomain
}