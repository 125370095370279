/*
 * @Author: 麦勇潮
 * @Date: 2020-12-30 10:36:38
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-03-04 15:17:02
 */

/** 
 * version 参数： 目前有两个可选参数
 * 【dq】表示大千官方渠道的个人中心，选择后，ui模块会加载对应的大千UI
 * 【mj】表示大千马甲渠道的个人中心，选择后，ui模块会加载对应的马甲UI
*/

export const version = 'mj'

export const sdkVersion = window.location.pathname.split('/')[1]
// 3000000598     38 555
export const userInfoData = {
"user_info":{"user_id":"2010002231","name":"j9330424683s","phone":"","email":"","password":"12345678","charge_limit":0,"real_name_status":0,"login_real_name_cfg":2,"charge_limit_view_cfg":0,"age":41},
"fuse_channel_info":{"game_id":"65","channel":"daqian","channel_id":"1152","package_id":"1","pf_game_id":"149"},
"ext_footer":{"game_version":"10","platform_version":"2.7","package_id":"1","game_id":"65","imei":"02f421d53e2c2ce1","mac":"02:00:00:00:00:00","device":"8f7635faf9ea408d8cadc0e35e031b57","screen":"2250*1036","model":"M2007J1SC","platform":"2","system":"10","system_language":"zh","net":"2","operator":"4","location":"0,0","simulator":"0","isroot":"0","is_qemu":"0","cpuinfo":"Hardware : Qualcomm Technologies, Inc SM8250","has_x86":"0","has_lightsensor":"0","has_bin":"0","abis":"[arm64-v8a, armeabi-v7a, armeabi]","utma":"5f2317ecdc140db0210ada27263d45c9","version":"2.6"},
"init_info":{"hongbao":1,'system_language':'zh-cn',"area_code":"cn","overseas":true}
}

export const roleInfoData = {"serverId":"201","serverName":"红包版-15服","roleId":"60293321","roleName":"爱在人生","roleLevel":72} 

export const payData = {"app_package":"妖精的尾巴","fee":"10000","channel":"daqian","sign":"872eb7db43e07add5fd010e7e4536086","screen":"1792x1080","package_id":"1","is_majia":"0","deviceId":"3553346882443","game_name":"妖精的尾巴","platform_version":"2.1","role_id":"1611667744589","tf_planid":"1001","model":"FRD-AL10","net":"3","cpOrderId":"1234567890_","brand":"honor","version_int":"3","cpuinfo":"","system_language":"zh","abis":"[arm64-v8a, armeabi-v7a, armeabi]","is_qemu":"0","server_id":"1","version":"2.2","phone":"","isroot":"0","local_uuid":"5081ea801dea4ea284090055c46aa7d7","sdk":"android","channel_id":"daqian","device":"3553346882443","server_name":"华中区","unique_label":"IMEI","from_id":"1","utma":"ce935076a1db91bf271f71d2133e103c","user_name":"班ellor","mac":"A8:C8:3A:F4:81:9C","has_lightsensor":"0","platform":"2.1","uid":"3000000598","os_api":"26","operators":"4","oaid":"","has_x86":"0","game_id":"185","timestamp":"1611667749874","game_version":"1.0","is_bind_phone":"0","simulator":"0","os":"1","os_version":"8.0.0","is_realname":"0","serial_number":"","callback_info":"21012621290878414554","notify_url":"http://yisdk-api.qianxi5.com/?ct\u003dnotify\u0026ac\u003ddaqian","has_bin":"0","app_name":"妖精的尾巴","pf_game_id":"471","serial":"GSL7N16B18002456","tf_pfname":"toutiao","package_name":"com.qianxi.yjdwbjh.qxhf","imei":"863791033316142","location":"0,0","android_id":"f9e77fa262a6a1f7"}

export const flag = {"invite_flag":1,"coupon_flag":1}

export const configData = {"hasClose": false}