/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author: 麦勇潮
 * @Date: 2020-12-30 15:23:50
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-03-26 15:01:40
 */
import originJSONP from 'jsonp'

export default (url, data, option) => {
  url += (url.indexOf('?') < 0 ? '?' : '&') + param(data)
  // console.log(url)
  return new Promise((resolve, reject) => {
    originJSONP(url, option, (err, data) => {
      if (!err) {
        resolve(data)
      } else {
        reject(err)
      }
    })
  })
}

const param = (data) => {
  let url = ''
  for (var k in data) {
    let value = data[k] !== undefined ? data[k] : ''
    url += `&${k}=${encodeURIComponent(value)}`
  }

  return url ? url.substring(1) : ''
}
