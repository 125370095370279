/*
 * @Author: 麦勇潮
 * @Date: 2020-12-29 20:55:18
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-03-01 14:17:36
 */
import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import './client/listen-message.js'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import VConsole from 'vconsole'
// new VConsole()
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
