/*
 * @Author: 麦勇潮
 * @Date: 2020-12-30 15:23:31
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-03-26 16:33:58
 */

import { requestEncrypt, returnDecrypt } from './fuse.auth'
import $jsonp from './jsonp';
import domain from '../config/domain'
const debug = true
const log = (name, log) => {
  if (debug) {
    console.log(name, log)
  }
}

const userAgent = navigator.userAgent.toLowerCase()

export const isMobile = /mobile/i.test(userAgent)

export const isAndroid = /android/i.test(userAgent)

export const isIOS = /iphone|ipad|ipod|macintosh/i.test(userAgent)

export const isWX = /MicroMessenger/i.test(userAgent)

export const getSession = key => JSON.parse(sessionStorage.getItem(key) || '{}')
export const saveSession = (key, value) => sessionStorage.setItem(key, JSON.stringify(value))
export const removeSession = key => sessionStorage.removeItem(key)

export const getLocal = key => JSON.parse(localStorage.getItem(key) || '{}')
export const saveLocal = (key, value) => localStorage.setItem(key, JSON.stringify(value))
export const removeLocal = key => localStorage.removeItem(key)

/**
 * @description: 校验是否为手机号码
 * @param {string} number
 * @return {*}
 */
export const verifyPhone = (number) => {
  if ((/^1[3456789]\d{9}$/.test(number))) {
    return true
  }
}
/**
 * @description: 校验是否为邮箱
 * @param {*} email
 * @return {*}
 */
export const verifyEmail = (email) => {
  if (/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email)) {
    return true
  }
}
/**
 * @description: 校验身份证是否有效
 * @param {*}
 * @return {*}
 */
export const verifyIdCard = (id) => {
  if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(id)) {
    return true
  }
}

/**
 * @description: 校验身份证名称
 * @param {*}
 * @return {*}
 */
export const verifyName = (name) => {
  if (/^[\u4e00-\u9fa5]{2,4}$/.test(name)) {
    return true
  }
}
export const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
/**
 * @description: 时间戳转换成年-月-日 时：分：秒
 * @param {*}
 * @return {*}
 */
export const formatDateTime = (timeStamp) => {
  let date = new Date();
  date.setTime(timeStamp * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let h = date.getHours();
  let d = date.getDate();
  m = m < 10 ? ('0' + m) : m;
  d = d < 10 ? ('0' + d) : d;
  h = h < 10 ? ('0' + h) : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return isIOS ? y + '/' + m + '/' + d + ' ' + h + ':' + minute + ':' + second : y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}
/**
 * @description: 显示消息
 * @param {*}
 * @return {*}
 */
export const showMsg = (txt = '') => {
  let msg = document.createElement('div')
  msg.innerHTML = `
        <div className="msg" style="
        position: fixed;
        left:50%;
        top:50%;
        margin-left:-2rem;
        font-size: 0.28rem;
        padding: 0.2rem 0.3rem;
        border-radius: 0.08rem;
        background: rgba(0, 0, 0, 0.8);
        line-height: 1.5;
        text-align: center;
        width: 4rem;
        color: #fff;
        word-break:break-all;
        z-index:9999999";
        id="show_tip">
            ${txt}
        </div>
    `
  document.body.appendChild(msg)
  setTimeout(() => {
    document.body.removeChild(msg)
  }, 1500)
}
/**
 * @description: sdk内和后端通讯方法
 * @param {*}
 * @return {*}
 */
export const sdkAjax = (...arg) => {
  loading.show()
  let payload = [...arg]
  // 加密KEY生成
  let KEY = String(new Date().getTime()).substr(0, 10)
  if (!payload[2]) payload[2] = {}
  // 建立筛选
  payload[2].filter = function (obj) {
    let { ct, ac } = obj
    let newObj = { ...obj }
    delete newObj.ac
    delete newObj.ct

    log(`${arg[0]}(input)`, arg[1])

    return {
      ct,
      ...(ac ? {
        ac
      } : {}),
      p: requestEncrypt(JSON.stringify(newObj), KEY).e,
      ts: KEY,
      js: 1
    }
  }
  return new Promise((resolve, reject) => {
    $jsonp(
      payload[2].domain = payload[2].domain || domain.__gameapiDomain.get(),
      payload[2].filter(payload[1]),
    ).then(res => {
      loading.hide()
      log(res)
      if (res.data && res.data.d) {
        res.data = JSON.parse(returnDecrypt(res.data.d, String(res.data.ts)).d)
      }
      if (res.code !== 0) {
        // ... 错误处理
        showMsg(res.msg)
      }
      log(`${arg[0]}(res)`, res)
      resolve(res)
    })
      .catch(e => {
        log('FN._sdk_ajax error:', e)
        loading.hide()
        reject({ code: 1, msg: e.message })
      })
  })
}
/**
 * @description: sdk内获取支付
 * @param  {...any} arg 
 */
export const payAjax = (...arg) => {
  loading.show()
  let payload = [...arg]
  if (!payload[2]) payload[2] = {}
  // 建立筛选
  payload[2].filter = function (obj) {
    let { ct, ac, p, ts, js } = obj
    let newObj = { ...obj }
    delete newObj.ac
    delete newObj.ct

    log(`${arg[0]}(input)`, arg[1])
    if (js) {
      return {
        ct,
        ...(ac ? {
          ac
        } : {}),
        p: p,
        ts: ts,
        js: js
      }
    } else {
      return {
        ct,
        ...(ac ? {
          ac
        } : {}),
        p: p,
        ts: ts
      }
    }

  }
  return new Promise((resolve, reject) => {
    $jsonp(
      payload[2].domain = payload[2].domain || domain.__gamepayDomain.get(),
      payload[2].filter(payload[1])
    ).then(res => {
      loading.hide()
      log(res.data)
      if (res.data && res.data.d) {
        res.data = JSON.parse(returnDecrypt(res.data.d, String(res.data.ts)).d)
      }
      log(`${arg[0]}(res)`, res)
      resolve(res)
    })
      .catch(e => {
        log('FN._sdk_ajax error:', e)
        loading.hide()
        reject({ code: 1, msg: e })
      })
  })
}

export const urlParams = (url) => {
  let theRequest = {}
  if (url.indexOf('?') !== -1) {
    let str = url.substr(1)
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

export const getURLparams = () => {
  let url = window.location.search // 获取url中"?"符后的字串
  return urlParams(url)
}


/**
* url地址修改
* @param url 待修改url
* @param arg 修改的参数名
* @param arg_val 修改的具体值
* @returns {String}
*/

export const changeURLArg = (url, arg, arg_val) => {
  var pattern = arg + '=([^&]*)';
  var replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
    var tmp = '/(' + arg + '=)([^&]*)/gi';
    // eslint-disable-next-line no-eval
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    // eslint-disable-next-line no-useless-escape
    if (url.match('[\?]')) {
      return url + '&' + replaceText;
    } else {
      return url + '?' + replaceText;
    }
  }
}


export const fuseSdkAjax = (...arg) => {
  // loading.show()
  let payload = [...arg]
  // 加密KEY生成
  let KEY = String(new Date().getTime()).substr(0, 10)
  if (!payload[2]) payload[2] = {}
  // 建立筛选
  payload[2].filter = function (obj) {
    let { ct, ac } = obj
    let newObj = { ...obj }
    delete newObj.ac
    delete newObj.ct

    console.log(`${arg[0]}(input)`, arg[1])

    return {
      ct,
      ac,
      p: requestEncrypt(JSON.stringify(newObj), KEY).e,
      ts: KEY,
      is_jsdk: 1
    }
  }
  return new Promise((resolve, reject) => {
    // console.log(payload[2].domain)
    $jsonp(
      payload[2].domain,
      payload[2].filter(payload[1])
    ).then(res => {
      // loading.hide()
      if (res.data && res.data.d) {
        res.data = JSON.parse(returnDecrypt(res.data.d, String(res.data.ts)).d)
      }
      if (res.code !== 0) {
        // ... 错误处理
        showMsg(res.msg)
      }
      console.log(`${arg[0]}(res)`, res)
      resolve(res)
    })
      .catch(e => {
        console.log('FN._fuse_sdk_ajax error:', e)
      })
  })
}

export const loading = {
  show() {
    let div = document.createElement('div')
    div.setAttribute('id', 'loadings')
    div.innerHTML = `
        <div class="loadEffect">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>`
    document.body.appendChild(div)
  },
  hide() {
    let dom = document.getElementById('loadings')
    document.body.removeChild(dom)
  }

}

export const throttle = (func, wait) => {
  let timeout

  return function () {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null
        func.call(this, arguments)
      }, wait)
    }
  }
}
// 加
export const floatAdd = (arg1, arg2) => {
  var r1, r2, m;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

//减    
export const floatSub = (arg1, arg2) => {
  var r1, r2, m, n;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2));
  //动态控制精度长度    
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

//乘    
export const floatMul = (arg1, arg2) => {
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try { m += s1.split(".")[1].length } catch (e) { }
  try { m += s2.split(".")[1].length } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}


//除   
export const floatDiv = (arg1, arg2) => {
  var t1 = 0, t2 = 0, r1, r2;
  try { t1 = arg1.toString().split(".")[1].length } catch (e) { }
  try { t2 = arg2.toString().split(".")[1].length } catch (e) { }

  r1 = Number(arg1.toString().replace(".", ""));

  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}