/*
 * @Author: 麦勇潮
 * @Date: 2020-12-29 20:55:18
 * @LastEditors: 脉迈
 * @LastEditTime: 2022-01-20 16:21:07
 */
import React, {Component,Fragment} from 'react'
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import {getSession,saveSession} from './common/utils'
import ScrollToTop from '@/components/scrollto-top'
import 'assets/font/iconfont.css';
import 'assets/style/var.scss';
import 'assets/style/theme/index.scss';
import Loadable from 'react-loadable';
import Loading from './components/loading'
import intl from 'react-intl-universal';
import {initLang} from './api/init'
if(JSON.stringify(getSession('sdkVersion'))==='{}') {saveSession('sdkVersion',window.location.pathname.split('/')[1])} // 存储v1/v2版本
if(getSession('sdkVersion')===''){saveSession('sdkVersion','v1')}

const V1Portrait = Loadable({loader: () => import('./pages/version-1/notice/portrait.js'), loading: Loading});

const V1Index = Loadable({loader: () => import('./pages/version-1/index/index.js'),loading: Loading});

const V1Hongbao = Loadable({loader: () => import('./pages/version-1/hongbao/index.js'), loading: Loading});


const V1ChangePassword = Loadable({loader: () => import('./pages/version-1/change-password/index.js'), loading: Loading});
const V1BindPhone = Loadable({loader: () => import('./pages/version-1/bind-phone/index.js'), loading: Loading});
const V1BindEmail = Loadable({loader: () => import('./pages/version-1/bind-email/index.js'), loading: Loading});
const V1RealName = Loadable({loader: () => import('./pages/version-1/real-name/index.js'), loading: Loading});
const V1Device = Loadable({loader: () => import('./pages/version-1/device/index.js'), loading: Loading});
const V1Recharge = Loadable({loader: () => import('./pages/version-1/recharge/index.js'), loading: Loading});
const V1RechargeWechat = Loadable({loader: () => import('./pages/version-1/recharge-wechat/index.js'), loading: Loading});
const V1Alipay404 = Loadable({loader: () => import('./pages/version-1/alipay404/index.js'), loading: Loading});
const V1Share = Loadable({loader: () => import('./pages/version-1/share/index.js'), loading: Loading});
const V1Deduction = Loadable({loader: () => import('./pages/version-1/deduction/index.js'), loading: Loading});
const V1Wallet = Loadable({loader: () => import('./pages/version-1/wallet/index.js'), loading: Loading});
const V1RechargeCoupon = Loadable({loader: () => import('./pages/version-1/recharge-coupon/index.js'), loading: Loading});
const V1ForgetPassword = Loadable({loader: () => import('./pages/version-1/forget-password/index.js'), loading: Loading});
const V1Coupon = Loadable({loader: () => import('./pages/version-1/coupon/index.js'), loading: Loading});
const V1DeleteAccount = Loadable({loader: () => import('./pages/version-1/delete-account/index.js'), loading: Loading});
const V1Role = Loadable({loader: () => import('./pages/version-1/role/index.js'), loading: Loading});
const V1BindWechat = Loadable({loader: () => import('./pages/version-1/bind-wechat/index.js'), loading: Loading});
const V1RandomCode= Loadable({loader: () => import('./pages/version-1/random-code/index.js'), loading: Loading});
const V1TransferUser= Loadable({loader: () => import('./pages/version-1/transfer-user/index.js'), loading: Loading});
const V1WxJump= Loadable({loader: () => import('./pages/version-1/wx-jump/index.js'), loading: Loading});
const V1Game= Loadable({loader: () => import('./pages/version-1/game/index.js'), loading: Loading});
const V1TransferPay= Loadable({loader: () => import('./pages/version-1/transfer-pay/index.js'), loading: Loading});

const V1CouponDetail = Loadable({loader: () => import('./pages/version-1/coupon/detail.js'), loading: Loading});



const V1Invite = Loadable({loader: () => import('./pages/version-1/invite/index.js'), loading: Loading});
const V1Message = Loadable({loader: () => import('./pages/version-1/message/index.js'), loading: Loading});
const V1MessgeDetail = Loadable({loader: () => import('./pages/version-1/message/detail.js'), loading: Loading});


const V1Gift = Loadable({loader: () => import('./pages/version-1/gift/index.js'), loading: Loading});
// 
const V1GiftDetail = Loadable({loader: () => import('./pages/version-1/gift/detail.js'), loading: Loading});


const V1VipConcat = Loadable({loader: () => import('./pages/version-1/vip-concat/index.js'), loading: Loading});
const V1Landscape = Loadable({loader: () => import('./pages/version-1/notice/landscape.js'),loading: Loading});

// V2版本
const V3LandscapeNotice = Loadable({loader: () => import('./pages/version-3/notice/landscape.js'), loading: Loading});
const V3PortraitNotice = Loadable({loader: () => import('./pages/version-3/notice/portrait.js'), loading: Loading});

// 公告V4版本
const V4LandscapeNotice = Loadable({loader: () => import('./pages/version-4/notice/landscape.js'), loading: Loading});
const V4PortraitNotice = Loadable({loader: () => import('./pages/version-4/notice/portrait.js'), loading: Loading});

class App extends Component{

  constructor(){
    super()
    this.state = {
      initDone: false,
      locales:{}
    }
  }

  loadLocales = (language) =>{
    let {locales} = this.state
    // react-intl-universal 是单例模式, 只应该实例化一次

        // 加载本地化JSON
        switch (language) {
          case 'Hant':
            intl.init({
              currentLocale: 'zh-TC', // TODO: determine locale here
              locales,
            })
            .then(() => {
              this.setState({
                initDone:true
              })
              saveSession('system_language','zh-Hant')
            });
            break;
            case 'Hans':
              intl.init({
                currentLocale: 'zh-CN', // TODO: determine locale here
                locales,
              })
              .then(() => {
                this.setState({
                  initDone:true
                })
                saveSession('system_language','zh-Hans')
              });
              break;
            case 'en':
              intl.init({
                currentLocale: 'en-US', // TODO: determine locale here
                locales,
              })
              .then(() => {
                this.setState({
                  initDone:true
                })
                saveSession('system_language','en-US')
              });
              break;
          default:
            intl.init({
              currentLocale: 'zh-CN', // TODO: determine locale here
              locales,
            })
            .then(() => {
              this.setState({
                initDone:true
              })
              saveSession('system_language','zh-Hans')
            });
            break;
        }
        
  }

  filterLanage = ()=>{
    let {area_code, system_language,overseas} = getSession('INIT_INFO')
    system_language = system_language ? system_language : 'zh'
    area_code = area_code ? area_code : 'cn'
    let sys_lan_lowerCase = system_language.toLowerCase() // 语言转小写
    let area_code_lowerCase = area_code.toLowerCase()  // 地区转小写
    if(sys_lan_lowerCase.includes('hans') || (sys_lan_lowerCase.includes('zh') && area_code_lowerCase==='cn')){
      this.loadLocales('Hans') // 简体
      return false
    }
    // if(sys_lan_lowerCase.includes('hant') || (sys_lan_lowerCase.includes('zh') && area_code_lowerCase!=='cn')){
    //   this.loadLocales('Hant') // 繁體
    //   return false
    // }
    if(sys_lan_lowerCase.includes('en')){
      this.loadLocales('en') // 簡體
      return false
    } else {
      if(overseas){
        this.loadLocales('en') // 英文
        return false
      }
      this.loadLocales('Hans')
      return false
    }
  }
  initTheme =()=>{
    let timer = setInterval(()=>{
      if(JSON.stringify(getSession('FUSE_CHANNEL_INFO'))!=="{}"){
        clearInterval(timer)
        let {game_id} = getSession('FUSE_CHANNEL_INFO')
        let key = `${game_id}`
        switch (key) {
          case '230':
            saveSession('theme','theme2')
            break;
        
          default:
            saveSession('theme','nomal')
            break;
        }
      }
    },10)
  }
  componentDidMount(){
    let loading = document.getElementById('first-loadings')
    document.body.removeChild(loading)
     // 初始化主题颜色
     this.initTheme()
    // this.loadLocales()
    let timer = setInterval(()=>{
      if(JSON.stringify(getSession('INIT_INFO'))!=="{}"){
        clearInterval(timer)
        
        
        initLang().then((res)=>{
          if(res.code===0){
            console.log("conf:", res.data.language_conf)
            this.setState({
              locales: res.data.language_conf
            })
            this.filterLanage()
          }
        })
       
      }
    },10)
    
  }
  render(){
    return(<Fragment>
       <BrowserRouter>
          <div>
            <ScrollToTop>   
                <Route path="/" exact render={()=> <Redirect to="/v1/personal" /> }></Route>  
                <Route path="/v1/personal" exact component={V1Index}></Route>
              
                <Route path="/v1/hongbao" exact component={V1Hongbao}></Route>
    
                <Route path="/v1/change-password" exact component={V1ChangePassword}></Route>
                <Route path="/v1/bind-phone" exact component={V1BindPhone}></Route>
                <Route path="/v1/bind-email" exact component={V1BindEmail}></Route>
                <Route path="/v1/real-name" exact component={V1RealName}></Route>
                <Route path="/v1/device" exact component={V1Device}></Route>
                <Route path="/v1/pay" exact component={V1Recharge}></Route>
                <Route path="/v1/pay-wechat" exact component={V1RechargeWechat}></Route>
                <Route path="/v1/transfer-pay" exact component={V1TransferPay}></Route>
                <Route path="/v1/alipay404" exact component={V1Alipay404}></Route>
                <Route path="/v1/share" exact component={V1Share}></Route>
                <Route path="/v1/deduction" exact component={V1Deduction}></Route>
                <Route path="/v1/wallet" exact component={V1Wallet}></Route>
                <Route path="/v1/notice/landscape" exact component={V1Landscape}></Route>
                <Route path="/v1/notice/portrait" exact component={V1Portrait}></Route>
                <Route path="/v1/recharge-coupon" exact component={V1RechargeCoupon}></Route>
                <Route path="/v1/coupon" exact component={V1Coupon}></Route>
                <Route path="/v1/coupon/detail/:id" exact component={V1CouponDetail}></Route>
                <Route path="/v1/invite" exact component={V1Invite}></Route>
                <Route path="/v1/message" exact component={V1Message}></Route>
                <Route path="/v1/message/detail/:id" exact component={V1MessgeDetail}></Route>
                <Route path="/v1/gift" exact component={V1Gift}></Route>
                <Route path="/v1/gift/:id" exact component={V1GiftDetail}></Route>
                <Route path="/v1/forget-password" exact component={V1ForgetPassword}></Route>
                <Route path="/v1/role" exact component={V1Role}></Route>
                <Route path="/v1/bind-wechat" exact component={V1BindWechat}></Route>
                <Route path="/v1/random-code" exact component={V1RandomCode}></Route>
                <Route path="/v1/transfer-user" exact component={V1TransferUser}></Route>
                <Route path="/v1/wx-jump" exact component={V1WxJump}></Route>
                <Route path="/v1/game" exact component={V1Game}></Route>
              
                <Route path="/v2/personal" exact component={V1Index}></Route>
            
                <Route path="/v2/hongbao" exact component={V1Hongbao}></Route>
              
                <Route path="/v2/change-password" exact component={V1ChangePassword}></Route>
                <Route path="/v2/bind-phone" exact component={V1BindPhone}></Route>
                <Route path="/v2/bind-email" exact component={V1BindEmail}></Route>
                <Route path="/v2/real-name" exact component={V1RealName}></Route>
                <Route path="/v2/device" exact component={V1Device}></Route>
                <Route path="/v2/pay" exact component={V1Recharge}></Route>
                <Route path="/v2/pay-wechat" exact component={V1RechargeWechat}></Route>
                <Route path="/v2/transfer-pay" exact component={V1TransferPay}></Route>
                <Route path="/v2/alipay404" exact component={V1Alipay404}></Route>
                <Route path="/v2/share" exact component={V1Share}></Route>
                <Route path="/v2/deduction" exact component={V1Deduction}></Route>
                <Route path="/v2/wallet" exact component={V1Wallet}></Route>
                <Route path="/v2/notice/landscape" exact component={V1Landscape}></Route>
                <Route path="/v2/notice/portrait" exact component={V1Portrait}></Route>
                <Route path="/v2/recharge-coupon" exact component={V1RechargeCoupon}></Route>
                <Route path="/v2/coupon" exact component={V1Coupon}></Route>
                <Route path="/v2/coupon/detail/:id" exact component={V1CouponDetail}></Route>
                <Route path="/v2/invite" exact component={V1Invite}></Route>
                <Route path="/v2/message" exact component={V1Message}></Route>
                <Route path="/v2/message/detail/:id" exact component={V1MessgeDetail}></Route>
                <Route path="/v2/gift" exact component={V1Gift}></Route>
                <Route path="/v2/gift/:id" exact component={V1GiftDetail}></Route>
                <Route path="/v2/vip-concat" exact component={V1VipConcat}></Route>
                <Route path="/v2/forget-password" exact component={V1ForgetPassword}></Route>

                <Route path="/hongbao" exact component={V1Hongbao}></Route>
                <Route path="/change-password" exact component={V1ChangePassword}></Route>
                <Route path="/bind-phone" exact component={V1BindPhone}></Route>
                <Route path="/bind-email" exact component={V1BindEmail}></Route>
                <Route path="/real-name" exact component={V1RealName}></Route>
                <Route path="/device" exact component={V1Device}></Route>
                <Route path="/pay" exact component={V1Recharge}></Route>
                <Route path="/pay-wechat" exact component={V1RechargeWechat}></Route>
                <Route path="/transfer-pay" exact component={V1TransferPay}></Route>
                <Route path="/alipay404" exact component={V1Alipay404}></Route>
                <Route path="/share" exact component={V1Share}></Route>
                <Route path="/deduction" exact component={V1Deduction}></Route>
                <Route path="/wallet" exact component={V1Wallet}></Route>
                <Route path="/notice/landscape" exact component={V1Landscape}></Route>
                <Route path="/notice/portrait" exact component={V1Portrait}></Route>
                <Route path="/recharge-coupon" exact component={V1RechargeCoupon}></Route>
                <Route path="/coupon" exact component={V1Coupon}></Route>
                <Route path="/coupon/detail/:id" exact component={V1CouponDetail}></Route>
                <Route path="/invite" exact component={V1Invite}></Route>
                <Route path="/message" exact component={V1Message}></Route>
                <Route path="/message/detail/:id" exact component={V1MessgeDetail}></Route>
                <Route path="/gift" exact component={V1Gift}></Route>
                <Route path="/gift/:id" exact component={V1GiftDetail}></Route>
                <Route path="/delete-account" exact component={V1DeleteAccount}></Route>
                <Route path="/random-code" exact component={V1RandomCode}></Route>
                <Route path="/role" exact component={V1Role}></Route>

                <Route path="/v3/notice/landscape" exact component={V3LandscapeNotice}></Route>
                <Route path="/v3/notice/portrait" exact component={V3PortraitNotice}></Route>

                <Route path="/v4/notice/landscape" exact component={V4LandscapeNotice}></Route>
                <Route path="/v4/notice/portrait" exact component={V4PortraitNotice}></Route>
            
              </ScrollToTop>
          </div>
        </BrowserRouter>
    </Fragment>)
  }
}

export default App;
