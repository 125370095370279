/* eslint-disable no-undef */
/*
 * @Author: 麦勇潮
 * @Date: 2021-01-04 10:56:40
 * @LastEditors: 蛋蛋
 * @LastEditTime: 2021-04-13 18:07:43
 */
import {isIOS,isAndroid,saveSession,getLocal,saveLocal} from '@/common/utils.js'
import {userInfoData,roleInfoData,payData} from '@/model/config'

const saveUserInfo = (userInfo) => {
  console.log('userInfo========>',userInfo)
  saveSession('CLIENT_USER_INFOR', userInfo)
  saveSession('FUSE_CHANNEL_INFO', userInfo.fuse_channel_info)
  saveSession('USER_INFO', userInfo.user_info)
  saveSession('extFooter', userInfo.ext_footer)
  saveSession('INIT_INFO', userInfo.init_info || {hongbao: 0}) // 控制红包是否开启
}
const saveRoleInfo = (roleBaseMsg) =>{
  saveSession('ROLE_BASE_MSG', roleBaseMsg)
}

const savePayParame = (payParame) =>{
  saveSession('PAY_PARAME',payParame)
}

const saveVipContent = (content) => {
  saveSession('VIP_CONTENT', content)
}


if(process.env.NODE_ENV==='development'){
  saveUserInfo(userInfoData)
  saveRoleInfo(roleInfoData)
  savePayParame(payData)
}

if(JSON.stringify(getLocal('flag'))==='{}'){
  saveLocal('flag', {"invite_flag":1,"coupon_flag":1})
}


if (isIOS) {
  window.getUserInfo = function (str) {
    let userInfo = str
    console.log(userInfo)
    if(str.ext_footer.game_version){
      saveUserInfo(userInfo)
    }else{
      userInfo.ext_footer.game_version = '1.0'
      console.log('=====>',userInfo)
      saveUserInfo(userInfo)
    }
  }
  window.getRoleInfo = function (str) {
    let roleBaseMsg = str
    saveRoleInfo(roleBaseMsg)
  }
  window.getPInfo = function (str) {
    console.log('支付参数:===>',str)
    savePayParame(str)
  }
  window.getServiceInfo = function (str){
    console.log(str)
    saveVipContent(str)
  }
  /**
   * 用户信息
   */
  try {
    // 获取初始用户信息
    let personalMethod = {'method': 'getUserInfo'}
    if(window.webkit.messageHandlers.GAMESDK){
      window.webkit.messageHandlers.GAMESDK.postMessage(personalMethod)
    }
    if(window.webkit.messageHandlers.QXSDK){
      window.webkit.messageHandlers.QXSDK.postMessage(personalMethod)
    }
    if(!window.webkit.messageHandlers.GAMESDK && !window.webkit.messageHandlers.QXSDK){
      window.addEventListener(
        'message',
        event => {
          if (event.data.type === 'getUserInfo') {
            saveUserInfo(event.data.data)
            // saveSession('CLIENT_USER_INFOR', event.data.data)
            // saveSession('FUSE_CHANNEL_INFO', event.data.data.fuse_channel_info)
            // saveSession('USER_INFO', event.data.data.user_info)
            // saveSession('extFooter', event.data.data.ext_footer)
            // saveSession('INIT_INFO', event.data.data.init_info) // 控制红包是否开启
          }
        },
        false
      )
    }
    

  } catch (error) {
    // 是在web端
    
    window.addEventListener(
      'message',
      event => {
        if (event.data.type === 'getUserInfo') {
          console.log('get info by web')
          saveUserInfo(event.data.data)
          // saveSession('CLIENT_USER_INFOR', event.data.data)
          // saveSession('FUSE_CHANNEL_INFO', event.data.data.fuse_channel_info)
          // saveSession('USER_INFO', event.data.data.user_info)
          // saveSession('extFooter', event.data.data.ext_footer)
          // saveSession('INIT_INFO', event.data.data.init_info) // 控制红包是否开启
        }
      },
      false
    )
  }
  /**
   * 支付参数
   */
  try {
    let personalMethod = {'method': 'getPInfo'}
    if( window.webkit.messageHandlers.GAMESDK){
      window.webkit.messageHandlers.GAMESDK.postMessage(personalMethod)
    }
    if(window.webkit.messageHandlers.QXSDK){
      window.webkit.messageHandlers.QXSDK.postMessage(personalMethod)
    }
    if(!window.webkit.messageHandlers.GAMESDK && !window.webkit.messageHandlers.QXSDK){
      // 是在web端
     window.addEventListener(
      'message',
        event => {
          if(event.data.type === 'getPInfo'){
            savePayParame(event.data.data)
          }
        },
        false
      )
    }
  } catch (error) {
    
     // 是在web端
     window.addEventListener(
      'message',
      event => {
        if(event.data.type === 'getPInfo'){
          savePayParame(event.data.data)
        }
      },
      false
    )
  }
  /**
   * 角色信息
   */
  try {
    // 获取角色信息
    let personalMethod = {'method': 'getRoleInfo'}
    if(window.webkit.messageHandlers.GAMESDK){
      window.webkit.messageHandlers.GAMESDK.postMessage(personalMethod)
    }
    if(window.webkit.messageHandlers.QXSDK){
      window.webkit.messageHandlers.QXSDK.postMessage(personalMethod)
    }
    if(!window.webkit.messageHandlers.GAMESDK && !window.webkit.messageHandlers.QXSDK){
      window.addEventListener(
        'message',
        event => {
          if (event.data.type === 'getRoleInfo') {
            saveRoleInfo(event.data.roleData)
          }
        },
        false
      )
    }
   
  } catch (error) {
    // 是在web端
    window.addEventListener(
      'message',
      event => {
        if (event.data.type === 'getRoleInfo') {
          saveRoleInfo(event.data.roleData)
        }
      },
      false
    )
  }

  // vip客服
  try {
    let personalMethod = {'method': 'getServiceInfo'}
    if( window.webkit.messageHandlers.GAMESDK){
      window.webkit.messageHandlers.GAMESDK.postMessage(personalMethod)
    }
    if(window.webkit.messageHandlers.QXSDK){
      window.webkit.messageHandlers.QXSDK.postMessage(personalMethod)
    }
  } catch (error) {
    
  }
  
} else if (isAndroid) {
  let userInfo = {}
    // 获取初始用户信息
    if(typeof GAMESDK === 'object'){
      try {
        userInfo = JSON.parse(GAMESDK.getUserInfo())
        saveUserInfo(userInfo)
      } catch (error) {
        
      }
     
      try {
        let roleBaseMsg = JSON.parse(GAMESDK.getRoleInfo())
        saveRoleInfo(roleBaseMsg)
      } catch (error) {
        
      }

      try {
        let vipContent = JSON.parse(GAMESDK.getServiceInfo())
        console.log(vipContent)
        saveVipContent(vipContent)
      }catch (error) {
        
      }
    }else if(typeof QXSDK === 'object'){
     
      try {
        let roleBaseMsg = JSON.parse(QXSDK.getRoleInfo())
        saveRoleInfo(roleBaseMsg)
      } catch (error) {
        
      }
      try {
        userInfo =  JSON.parse(QXSDK.getUserInfo())
        saveUserInfo(userInfo)
      } catch (error) {
        
      }
      
    }else if(typeof webView === 'object'){
      let payParame = {}
      try {
        payParame = JSON.parse(webView.getPInfo())
        userInfo = JSON.parse(webView.getUserInfo())
        saveUserInfo(userInfo)
        savePayParame(payParame)
      } catch (error) {
        
      }

    }else{
      window.addEventListener(
        'message',
        event => {
          console.log(event.data.data)
          if (event.data.type === 'getUserInfo') {
            saveUserInfo(event.data.data)
          }
          if (event.data.type === 'getRoleInfo') {
            saveRoleInfo(event.data.roleData)
          }
          if (event.data.type === 'getPInfo') {
            savePayParame(event.data.data)
          }
        },
        false
      )
    }
} else {
  // pc端 web
  window.addEventListener(
    'message',
    event => {
      console.log(event.data.data)
      if (event.data.type === 'getUserInfo') {
        saveUserInfo(event.data.data)
      }
      if (event.data.type === 'getRoleInfo') {
        saveRoleInfo(event.data.roleData)
      }
      if (event.data.type === 'getPInfo') {
        savePayParame(event.data.data)
      }
    },
    false
  )
}
